export const getCookies = () => {
  const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [name, value] = cookie.split('=');
    if (!name.includes('_expires')) {
      acc[name] = { value, expires: null };
    }
    return acc;
  }, {});

  // Retrieve the expiration dates
  document.cookie.split('; ').forEach(cookie => {
    const [name, value] = cookie.split('=');
    if (name.includes('_expires')) {
      const cookieName = name.replace('_expires', '');
      if (cookies[cookieName]) {
        cookies[cookieName].expires = value;
      }
    }
  });

  return cookies;
};

  