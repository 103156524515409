import { useLocation, useNavigate } from "react-router-dom"
import { useState, useEffect, useContext} from "react";
import loadingGif from '../../loading.gif'
import defaultAvatar from '../../image/profile-picture.png'
import { UserContext } from "../../userProvider";
import Cookie from 'js-cookie'
import { useTranslation } from "react-i18next";
export default function Greeting() {
  const [count, setCount] = useState(3); 
    const location = useLocation()
    const navigate = useNavigate()
    const {t,i18n} = useTranslation()
    const language = i18n.language || 'en'
    const { saveUserData, saveUserImage, userData } = useContext(UserContext);
    const {responseData} = location.state || {}
    saveUserData(responseData)
    const sessionDataString = JSON.stringify(responseData)
    Cookie.set('session', sessionDataString, { expires: 7})
    const { userImage} = useContext(UserContext);
    useEffect(() => {
      const timer = setInterval(() => {
        setCount(prevCount => {
          if (prevCount === 1) {
            navigate(`/${language}/setting`);
            window.location.reload()
            return 0;
          } else {
            return prevCount - 1;
          }
        });
      }, 1000); 
  
      return () => clearInterval(timer); 
    }, [navigate]);
    
    const Welcome = () => {
      if(i18n.language == 'en'){
        return(
          <p style={{color:'#fff', fontSize: '1.4rem', marginTop: 50, display:"flex", alignItems:'center'}}>You will be redirect to Home page in {count} second <img src={loadingGif} style={{marginLeft:5,width: 20, height: 20}}/></p> 
        )
      }else if(i18n.language == 'ja') {
        return(
          <p style={{color:'#fff', fontSize: '1.4rem', marginTop: 50, display:"flex", alignItems:'center'}}>{count} 秒後にホームページにリダイレクトされます <img src={loadingGif} style={{marginLeft:5,width: 20, height: 20}}/></p> 
        )
      }else if(i18n.language == 'vi'){
        return(
          <p style={{color:'#fff', fontSize: '1.4rem', marginTop: 50, display:"flex", alignItems:'center'}}>Quý khách sẽ được chuyển hướng đến trang chủ trong {count} giây<img src={loadingGif} style={{marginLeft:5,width: 20, height: 20}}/></p> 
        )
      }else {
        return(
        <p style={{color:'#fff', fontSize: '1.4rem', marginTop: 50, display:"flex", alignItems:'center'}}>{count}초 후에 홈페이지로 이동됩니다.<img src={loadingGif} style={{marginLeft:5,width: 20, height: 20}}/></p> 
        )
      }
    }
    return (
        <>
        {responseData.status == 2 ? (
          <>
            <div className="d-flex flex-column align-items-center justify-content-center login-welcome-container" style={{ backgroundColor:'#482979'}}>
              <img src="https://azumayavietnam.com/image/logo/azlogo.png" alt="logo" className="login-welcome-image"/>
                    <h1 className="login-welcome1">{t("home.welcome_title")}!</h1>
                    <h2 className="login-welcome2">{t("login.welcome-sentence")}</h2>
                    <Welcome />
            </div>
            </>
        ) : (
          <>
          <div className="d-flex flex-column align-items-center justify-content-center login-welcome-container" style={{ backgroundColor:'#482979'}}>
            <img src="https://azumayavietnam.com/image/logo/azlogo.png" alt="logo" className="login-welcome-image"/>
                  <h1 className="login-welcome1">{t("login.welcome-back")}, {responseData.data.name}!</h1>
                  <img src={responseData.data.avatar || userImage || defaultAvatar } alt="avatar" style={{borderRadius: '50%', height:100, width: 100}} /> 
                  <Welcome />
          </div>
          </>
        )}

        </>
    )
}