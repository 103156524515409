import React, { useContext } from 'react';
import { UserContext } from './UserContextv2'; // Ensure the path is correct

const UserProfile = () => {
  const { state } = useContext(UserContext);

  return (
    <div>
      {state.user ? (
        <div style={{margin: 100}}>
          <h1>Welcome, {state.user.data.name || ''}</h1>
          <p>Email: {state.user.data.email || ''}</p>
        </div>
      ) : (
        <div>Please log in</div>
      )}
    </div>
  );
};

export default UserProfile;
