import { useContext } from 'react';
import { UserContext } from './UserContextv2'; // Ensure the path is correct
import { useNavigate } from 'react-router-dom'; // Assuming you use react-router-dom

const postDataMail = async (user, dispatch, language, navigate) => {
    try {
      const response = await fetch('http://localhost:3000/api/user/a4g17146-gg44-4323-135d-29at4qaf214w', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();

      if (responseData.msg === 'User not active') {
        alert('Please activate your account first! We have already sent an email to your email.');
      } else if (responseData.msg === 'Password error') {
        alert('Password is not correct! Please try again.');
      } else {
        // Dispatch login action
        dispatch({
          type: 'LOGIN',
          payload: responseData,
        });

        // Navigate to the greeting page
        // navigate(`/${language}/greeting`, { state: { responseData: responseData } });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }

export default postDataMail;
