// src/UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookie from 'js-cookie'
export const UserContext = createContext({
  userData: null,
  saveUserData: () => {},
  getUserData: () => {},
});

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const saveUserData = (data) => {
    setUserData(data);
    localStorage.setItem('userData', JSON.stringify(data));  // Save user data to localStorage
  };
  const saveUserImage = (img) => {
    setUserImage(img);
    localStorage.setItem('userImage', JSON.stringify(img)); // Save user image to local
  }
  const getUserData = () => {
    const savedUserData = localStorage.getItem('userData');
    if (savedUserData) {
      setUserData(JSON.parse(savedUserData));
    }
  };
  const getUserImage = () => {
    const saveUserImage = localStorage.getItem('userImage');
    if (saveUserImage) {
      setUserImage(JSON.parse(saveUserImage));
    }
  }
  const logout = () => {
    setUserData(null);
    setUserImage(null)
    localStorage.removeItem('userImage')
    localStorage.removeItem('userData');
    Cookie.remove('session')
  };
  useEffect(() => {
    getUserData(); 
    getUserImage()
  }, []); 
  return (
    <UserContext.Provider value={{ userData, saveUserData, getUserData, userImage, saveUserImage, getUserImage, logout}}>
      {children}
    </UserContext.Provider>
  );
};
