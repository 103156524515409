// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from './JSON/en.json'
import translationJA from './JSON/ja.json'
import translationVIE from './JSON/vie.json'
import translationKOR from './JSON/kor.json'
import Cookies from 'js-cookie';
const languages = ['en', 'ja', 'vi', 'ko']
const resources = {
  en: {
    translation: translationEN
},
  ja: {
    translation: translationJA
},
  vi: {
    translation: translationVIE
  },
  ko: {
    translation: translationKOR
  }
}
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallback: 'en', 
    debug: false,
    detection: {
      // Order of detection
      order: ['cookie', 'navigator'],
      // Cache user language in cookies
      caches: ['cookie'],
      cookieOptions: { path: '/', sameSite: 'strict' },
    },
    whitelist: languages,
    // keySeparator: '.', // Remove dots in key names
    interpolation: {
      escapeValue: false, // Not needed for React
    },
    backend: {
      loadPath: '/JSON/{{lng}}.json',
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
