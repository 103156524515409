import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
export default function Recruitment() {
    const {t, i18n} = useTranslation()
    const language = i18n.language
    const [jobData, setJobData] = useState()
    const [startDate, setStartDate] = useState('2011-01-01');
    const today = new Date().toISOString().split('T')[0]
    const [endDate, setEndDate] = useState(today);
    const [yearCount, setYearCount] = useState(0);
  
    const calculateYearCount = () => {
      // Create Date objects from the input strings
      const start = new Date(startDate);
      const end = new Date(endDate);
  
      // Check if both dates are valid
      if (!isNaN(start) && !isNaN(end)) {
        // Calculate the difference in years
        let yearsDiff = end.getFullYear() - start.getFullYear();
  
        // Adjust for the month/day differences
        // if (
        //   end.getMonth() < start.getMonth() ||
        //   (end.getMonth() === start.getMonth() && end.getDate() < start.getDate())
        // ) {
        //   yearsDiff--;
        // }
  
        setYearCount(yearsDiff);
      } else {
        setYearCount(0);
      }
    };
    useEffect(() => {
      calculateYearCount();
    }, [startDate, endDate]);
  
    const getData = async () => {
      try {
        const response = await fetch(`/api/recruitment/856c6d86-5d89-411a-aff5-2cbb9d3bfa04`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        setJobData(responseData)   
      } 
      catch (error) {
        console.error('Error:', error);
      }
  }
  useEffect(() => {
    getData()
  }, [])
  const groupJobsByPosition = (data) => {
    const grouped = {}; // Initialize an empty object to store grouped jobs by position.
  
    // Object.values(data) extracts all the job arrays from the input 'data' object.
    Object.values(data).forEach((jobs) => {
      
      // Iterate over each job within the array of jobs.
      jobs.forEach((job) => {
        
        // Check if the 'grouped' object already has an array for the current job's position.
        if (!grouped[job.position]) {
          
          // If not, create an empty array for this position.
          grouped[job.position] = [];
        }
        
        // Push the current job into the array for its position.
        grouped[job.position].push(job);
      });
    });
    
    // Return the grouped jobs object.
    return grouped;
};
  const groupedJobs = groupJobsByPosition(jobData?.data || {});

    return (
    <>
     <div className="recruitment_header">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <h1>Tuyển Dụng</h1>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="re__breadcrumb">
            <ul className="breadcrumb__list">
              <li className="breadcrumb__item">
                <Link to={`/${language}/`}>
                  <i className="fa-solid fa-house"></i>
                </Link>
              </li>
              <li className="breadcrumb__item">/</li>
              <li className="breadcrumb__item">
                <Link to = {`/${language}/recruitment/`} className="breadcrumb__title">
                  Tuyển Dụng 
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
        <div className="d-flex justify-content-center">
            <div style={{fontSize: "3.5rem", fontWeight:'bold', color:"#482979", borderBottom: "3px solid #482979"}}>Cơ Hội Làm Việc Tại Khách Sạn Azumaya ?</div>
        </div>
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-7 d-flex align-items-center">
            <div style={{fontSize: "1.4rem" }}> <span style={{fontWeight:'bold', color:"#482979"}}>Azumaya</span> là một thương hiệu khách sạn kiểu Nhật dành cho khách du lịch công tác, có mặt lần đầu tiên tại Việt Nam từ giữa năm 2011. 
           <br/>Khách sạn hoạt động với mục tiêu tạo cho khách hàng Nhật Bản sự thoải mái và cảm giác an toàn như ở nhà, bằng những tiện nghi và dịch vụ mang phong cách Nhật Bản.
           <br/> <br/>Chúng tôi đặt <span style={{fontWeight:'bold', color:"#482979"}}>"Omotenashi"</span>  (lòng hiếu khách của người Nhật)  trong trái tim để phục vụ khách lưu trú niềm vui trong cuộc sống ở đất nước xa nhà.
           <br/> <br/>Trong hơn <span style={{fontWeight:'bold', color:"#482979"}}>{yearCount} năm </span>hoạt động, <span style={{fontWeight:'bold', color:"#482979"}}>Khách sạn Azumaya</span> đã phát triển và mở rộng với nhiều chi nhánh ở Hồ Chí Minh, Hà Nội, Đà Nẵng, Hải Phòng và thị trường Đông Nam Á như Campuchia. Chúng tôi tự tin đã đem đến sự hài lòng cho khách lưu trú bằng một không gian thoải mái giải tỏa căng thẳng, xoa dịu sự mệt mỏi sau một ngày làm việc với các dịch vụ kiểu Nhật như bồn tắm nước nóng ngoài trời, massage chân; cũng như bữa sáng Nhật Bản đích thực cung cấp năng lượng mỗi ngày cho khách lưu trú.
           <br/> <br/>Để có thể được như vậy, chúng tôi hiểu ra rằng con người chính là yếu tố quyết định đối với sự phát triển và thành công hơn nữa của Công ty. Chúng tôi quan niệm rằng, nếu nhân viên không hạnh phúc thì sẽ rất khó có thể tạo ra một dịch vụ để khách hàng cảm thấy hài lòng.
           <br/>Vì thế, chúng tôi luôn không ngừng nỗ lực xây dựng một môi trường làm việc thuận lợi - nơi có thể mang lại hạnh phúc cho nhân viên cả về vật chất lẫn tinh thần.
           <br/> <br/>Là nhân viên của <span style={{fontWeight:'bold', color:"#482979"}}>Khách sạn Azumaya</span> - không những bạn sẽ được đào tạo về các kỹ năng, nghiệp vụ chuyên môn mà còn có cơ hội được hướng dẫn từ những điều tưởng chừng như rất đơn giản trong cách giao tiếp, ứng xử để có thể xây dựng mối quan hệ tốt đẹp với mọi người xung quanh.
           <br/> <br/>Hãy gia nhập đội ngũ <span style={{fontWeight:'bold', color:"#482979"}}>Khách sạn Azumaya</span> để trở thành 1 phần quan trọng không thể thiếu của công ty chúng tôi. 
           <br/>Cơ hội tạo thu nhập, học hỏi nâng cao tiếng Nhật cũng như phát huy hết khả năng của mình.
           <br/> <br/> <br/>Ngoài ra , nếu bạn có mong muốn đi làm việc ở Nhật Bản, bạn có thể thử sức đồng hành cùng <span style={{fontWeight:'bold', color:"#482979"}}>Khách sạn Azumaya</span> trong một năm để được đào tạo tiếng Nhật và chuyên môn, chúng tôi sẽ giúp bạn đến gần với mục tiêu của mình. 
            </div>
                </div>
            <div className="col-md-5 d-flex justify-content-center align-items-center mt-3">
            <img src="https://azumayavietnam.com/image/hcm/tvl1/facility/OSTVL1_1.webp" width={450} height={700}/>
            </div>
            </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
            <div style={{fontSize: "3.5rem", fontWeight:'bold', color:"#482979", borderBottom: "3px solid #482979"}}>Các vị trí đang tuyển tại Azumaya Việt Nam</div>
        </div>
        <div className="cointainer mt-5">
                    <div className="table_container">
                      <table className="room__tariff">
                        <tbody>
                          <tr>
                            <th
                              style={{ width: "40%" }}
                            >
                              Vị trí tuyển dụng
                            </th>
                            <th style={{ width: "20%" }}>
                              Nơi làm việc
                            </th>
                            <th style={{ width: "20%" }}>
                              Ngày hết hạn
                            </th>
                          </tr>
                          <tr>
                          </tr>
                          {Object.entries(groupedJobs).map(([position, jobs]) => (
                              jobs.map((job, index) => {
                                const deadLine = new Date(job.deadline)
                                return(
                                <tr key={job._id}>
                                  {index === 0 && (
                                    <td
                                      className="pl-4"
                                      style={{ textAlign: 'left', fontWeight: 'bold' }}
                                      rowSpan={jobs.length}
                                    >
                                      {position}
                                    </td>
                                  )}
                                  <td style={{ textAlign: 'center', fontWeight: 'bold' }}>
                                    {job.location}
                                  </td>
                                  {/* <td>{new Date(job.deadline).toLocaleDateString()}</td> */}
                                  <td>{Intl.DateTimeFormat('en-GB').format(deadLine)}</td>
                                </tr>
                              )})
                            ))}
                        </tbody>
                      </table>
        </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
            <div style={{fontSize: "3.5rem", fontWeight:'bold', color:"#482979", borderBottom: "3px solid #482979"}}>Cơ hội được làm việc thực tế tại Nhật Bản</div>
        </div>
        <div className="container mt-5">
            <div className="row">
                <div className="col-md-5 d-flex justify-content-center align-items-center">
                    <img src="https://www.hilton.com/im/en/NoHotel/19361896/shutterstock-667925704.jpg?impolicy=crop&cw=3800&ch=2533&gravity=NorthWest&xposition=0&yposition=1&rw=1280&rh=856" width={450} height={300}/>
                    {/* <img src="https://www.state.gov/wp-content/uploads/2019/04/Japan-2107x1406.jpg" width={400} height={300}/> */}
                </div>
                <div className="col-md-7 d-flex align-items-center mt-3">
            <div style={{fontSize: "1.4rem" }}>Nhật Bản là một đất nước phát triển với nhiều đặc điểm văn hoá đặc sắc cũng như nhiều triết lý kinh doanh đáng để cả thế giới phải học hỏi.
            <br/><br/>Giá trị và sự thành công của chúng tôi bắt nguồn từ đặc trưng rất quan trọng trong ngành dịch vụ Nhật Bản đó là <span style={{fontWeight:'bold', color:"#482979"}}>"Omotenashi - Lòng hiếu khách"</span>.
            <br/><br/> Thông qua việc trao cơ hội được tận mắt thấy được tinh thần Omotenashi trong ngành dịch vụ nói riêng và đời sống thường ngày tại Nhật nói chung, chúng tôi tin rằng các bạn có thể học hỏi và phát triển nhiều hơn nữa. Với phương châm <span style={{fontWeight:'bold', color:"#482979"}}>"Chọn đúng người - Gửi đúng cách - Làm tại đúng công ty"</span>, chúng tôi liên kết trực tiếp với các hệ thống khách sạn /Ryokan /nhà hàng tại Nhật mà không qua bất cứ bên trung gian nào. Bởi vậy, sẽ tiết kiệm được những chi phí không cần thiết trong quá trình đến Nhật.
            <br/><br/>Hãy để <span style={{fontWeight:'bold', color:"#482979"}}>Azumaya</span> trang bị cho bạn một hành trang thật vững chắc trên con đường hướng tới đất nước mặt trời mọc bạn nhé.
            </div>
                </div>
            </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
            <div style={{fontSize: "3.5rem", fontWeight:'bold', color:"#482979", borderBottom: "3px solid #482979"}}>Thông tin tuyển dụng</div>
        </div>
        <div className="d-flex justify-content-center align-items-center mt-5">
        <div className="table_container">
        <table className="room__tariff">
          <tbody>
          <tr>
            <td rowSpan={2} style={{fontSize: "1.4rem", padding:5}}>Khu vực Hà Nội <br/><span style={{fontWeight: 'bold', color:'#482979'}}>Mrs. Diệu Linh </span></td>
            <td style={{fontSize: "1.4rem", textAlign:'left', paddingLeft: 10}}><i className="fa-solid fa-phone purple mr-1"></i> <a href="tel:096 507 9838" >096 507 9838</a></td>
          </tr>
          <tr>
            <td style={{fontSize: "1.4rem", textAlign:'left', paddingLeft: 10}}><i className="fa-solid fa-envelope purple mr-1"></i> <a href="mailto:azhn.recruitment@azumayavietnam.com">azhn.recruitment@azumayavietnam.com</a></td>
          </tr>
          <tr>
            <td rowSpan={2} style={{fontSize: "1.4rem", padding:5}}>Khu vực Hồ Chí Minh <br/><span style={{fontWeight: 'bold', color:'#482979'}}>Ms. Trang</span> </td>
            <td style={{fontSize: "1.4rem", textAlign:'left', paddingLeft: 10}}><i className="fa-solid fa-phone purple mr-1"></i> <a href="tel:076 559 2669">076 559 2669</a></td>
          </tr>
          <tr>
            <td style={{fontSize: "1.4rem", textAlign:'left', paddingLeft: 10}}><i className="fa-solid fa-envelope purple mr-1"></i> <a href="mailto:adminhcm@azumayavietnam.com">adminhcm@azumayavietnam.com</a></td>
          </tr>
          <tr>
            <td rowSpan={2} style={{fontSize: "1.4rem", padding:5}}>Khu vực Đà Nẵng <br/><span style={{fontWeight: 'bold', color:'#482979'}}>Ms. Hưng</span> </td>
            <td style={{fontSize: "1.4rem", textAlign:'left', paddingLeft: 10}}><i className="fa-solid fa-phone purple mr-1"></i> <a href="tel:0236 3743 888">0236 3743 888</a></td>
          </tr>
          <tr>
            <td style={{fontSize: "1.4rem", textAlign:'left', paddingLeft: 10}}><i className="fa-solid fa-envelope purple mr-1"></i> <a href="mailto:managerdanang@azumayavietnam.com">managerdanang@azumayavietnam.com</a></td>
          </tr>
          <tr>
            <td rowSpan={2} style={{fontSize: "1.4rem", padding:5}}>Khu vực Hải Phòng <br/><span style={{fontWeight: 'bold', color:'#482979'}}>Ms. Nhi</span> </td>
            <td style={{fontSize: "1.4rem", textAlign:'left', paddingLeft: 10}}><i className="fa-solid fa-phone purple mr-1"></i> <a href="tel:0225 3828 969">0225 3828 969</a></td>
          </tr>
          <tr>
            <td style={{fontSize: "1.4rem", textAlign:'left', paddingLeft: 10}}><i className="fa-solid fa-envelope purple mr-1"></i> <a href="mailto:managerhaiphong1@azumayavietnam.com">managerhaiphong1@azumayavietnam.com</a></td>
          </tr>
          </tbody>
        </table>
        </div>
        </div>
        </div>
    </>
    )
}