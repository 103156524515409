import { useState, useContext, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom"
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../userProvider";
import { Flip, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function SignUp() {
	const {t, i18n} = useTranslation()
	const language = i18n.language || 'en'
	const genderValue = t("gender", { returnObjects: true });
	const navigate = useNavigate()
	const [day, setDay] = useState('')
	const [month, setMonth] = useState('')
	const [year, setYear] = useState('')
	const [familyName, setFamilyName] = useState('')
	const [givenName, setGivenName] = useState('')
	const [gender, setGender] = useState('')
	const [email, setEmail] = useState('')
	const [phone, setPhone] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [errors, setErrors] = useState({});
	const [errorMessage, setErrorMessage] = useState('');
	const domain = 'tunglam.site'
	const {status} = location.state || {}
	const [loading, setLoading] = useState(false)
	const { saveUserData, saveUserImage } = useContext(UserContext);
	const inputRef1 = useRef(null);
	const inputRef2 = useRef(null);
	const inputRef3 = useRef(null);
	const inputRef4 = useRef(null);
	const inputRef5 = useRef(null);
	const inputRef6 = useRef(null);

	const handleKeyDown = (e, ref) => {
		const input = ref.current;    
		const { selectionStart, selectionEnd } = input;
	
		if (e.key === 'ArrowLeft') {
		  e.preventDefault();
		  // Move cursor left
		  if (selectionStart > 0) {
			input.setSelectionRange(selectionStart - 1, selectionStart - 1);
		  }
		} else if (e.key === 'ArrowRight') {
		  e.preventDefault();
		  // Move cursor right
		  if (selectionEnd < input.value.length) {
			input.setSelectionRange(selectionStart + 1, selectionStart + 1);
		  }
		}
	  };
	  const validateEmail = (email) => {
		// Regular expression for email validation
		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return emailRegex.test(email);
	  };
	const validateForm = () => {
		let errors = {}
		let isVaLid = true
	
		if (!familyName) {
		  errors.familyName = 'required';
		  isVaLid = false
		} 
		if (!givenName ) {
		  errors.givenName = 'required';
		  isVaLid = false
		} 
		if (!gender ) {
			errors.gender = 'required';
			isVaLid = false
		  } 
		if (!day ) {
			errors.day = 'required';
			isVaLid = false
		  } 
		if (!month ) {
			errors.month = 'required';
			isVaLid = false
		  } 
		if (!year ) {
			errors.year = 'required';
			isVaLid = false
		  } 
		if (!email) {
		  errors.email = 'required';
		  isVaLid = false
		}  else if (!validateEmail(email)) {
		  errors.email = 'Invalid email format';
		  isVaLid = false
		}
		if (!phone) {
		  errors.phone = 'required';
		  isVaLid = false
		} 
		if (!password) {
			errors.password = 'required';
			isVaLid = false
		  }
		if (!confirmPassword) {
			errors.password = 'required';
			isVaLid = false
		}else if(confirmPassword != password)  {
			errors.confirmPassword = 'password not match'
			isVaLid =false;
		  }
	  setErrors(errors);
	  return isVaLid
	  }
	const handleConfirmPasswordChange = (e) => {
		const value = e.target.value;
		setConfirmPassword(value);
	
		if (password !== value) {
		  setErrorMessage('Password do not match');
		} else {
		  setErrorMessage('');
		}
	  };
	function DayPicker() {
		const minDay = 1;
		const maxDay = 31;
		const birthDay = 0;
		const options = [];
		for (let i = minDay; i <= maxDay; i++) {
		  const day = birthDay + i;
		  options.push(
			<option value={day} key={day}>
			  {day <=9 ? '0' + day : day}
			</option>
		  );
		}
		return (
		  <select
			className={ errors.day ? "form__content validate_failed" : "form__content mb-0 mr-0"}
				style={{fontSize: '1.2rem'}}
				value={day}
				onChange={(e) => {
					setDay(e.target.value)
					errors.day = ''
			}}
		  >
			<option className="first-opt">
			  {t("reservation.day")}
			</option>
			{options}
		  </select>
		);
	  }
	function MonthPicker() {
		const minMonth = 0;
		const maxMonth = 11;
		const birthMonth = 1;
	
		const options = [];
		for (let i = minMonth; i <= maxMonth; i++) {
		  const month = birthMonth + i;
		  options.push(
			<option value={month} key={month}>
			  {month <=9 ? '0' + month : month}
			</option>
		  );
		}
		return (
		  <select
			className={ errors.month ? "form__content validate_failed" : "form__content mb-0 mr-0"}
				style={{fontSize: '1.2rem'}}
				value={month}
				onChange={(e) => { 
					setMonth(e.target.value)
					errors.month = ''
				}}
		  >
			<option className="first-opt">
			  {t("reservation.month")}
			</option>
			{options}
		  </select>
		);
	  }
	function YearPicker() {
		const minYear = 1925;
		const maxYear = 2005;
		const birthYear = 0;
	
		const options = [];
		for (let i = minYear; i <= maxYear; i++) {
		  const year = birthYear + i;
		  options.push(
			<option value={year} key={year}>
			  {year}
			</option>
		  );
		}
		return (
		  <select
			className={ errors.year ? "form__content validate_failed" : "form__content mb-0 mr-0"}
				style={{fontSize: '1.2rem'}}
				value={year}
				onChange={(e) => {
					setYear(e.target.value)
					errors.year = ''
				}}
		  >
			<option className="first-opt">
			  {t("reservation.year")}
			</option>
			{options}
		  </select>
		);
	  }
	const dataPost = 
	  {
		name: familyName+" "+givenName,
		familyName: familyName,
		givenName: givenName,
		birthDate: day+"-"+month+"-"+year,
		gender: gender,
		email: email, 
		phone: phone,
		password: password, 
		confirmPassword: confirmPassword,
		language: language
	  }
	  const postData = async (data) => {
			setLoading(true)
			try {
			  const response = await fetch(`/api/user/bab17146-4555-4623-93a3-a9ab420f25a4`, {
				method: 'POST',
				headers: {
				  'Content-Type': 'application/json',
				},
				body: JSON.stringify(data)
				
			  });
			  
			  if (!response.ok) {
				throw new Error('Network response was not ok');
			  }
			  const responseData = await response.json();
			  if(responseData.status == 0) {
				toast.error(responseData.msgLang[language])
			  }else {
				toast.success(responseData.msgLang[language])
				  setTimeout(() => {
					setLoading(false);
					navigate(`/${language}/login`, {state: {responseData: responseData}})
				}, 5000);
			  }
			} 
			catch (error) {
			  console.error('Error:', error);
			}
	}
	const handlePostData = (e) => {
		e.preventDefault();
		if(validateForm){
			postData(dataPost)
			
		}
	}
     return(
		<>
		{/* {loading && (
     <div className="container">
      <div style={{height: "100%", width: "100%", position: 'absolute', left:0, right: 0, backgroundColor: 'rgba(0,0,0,0.3)', zIndex:1}} className="row justify-content-center align-items-center ml-0 mr-0">
        <Spinner animation="border" role="status" style={{color: "#482979"}}>
         </Spinner>
      </div>
     </div>
    )} */}
			<div className="container_login container d-flex justify-content-center">
			<div className="left_signup" style={{backgroundImage:`url(https://azumayavietnam.com/image/rtbr/rtbrdn3.jpeg)`}}></div>
			<div className="right_signup">
				<div className="login_header">
				<div className="login_title animation a0 d-flex justify-content-center">
				<img style={{width:"30%", height:"35%"}} src="https://azumayavietnam.com/image/logo/logo_az_k_bg.png"/>
				</div>
				<h2 className="center mt-3 animation a1">{t("login.signup_info")}</h2>
				</div>
					<form className="login_card-form">
						<div className="row">
							<div className="col-md-6">
							<div className="login_input animation a2">
							<input 
							ref={inputRef1}
							onKeyDown={(e) => handleKeyDown(e, inputRef1)}
							type="text" 
							className="login_input-field" 
							value={familyName}
							onChange={(e) => setFamilyName(e.target.value)}
							required/>
							<label className="login_input-label ml-0">{t("reservation.family-name")}</label>
						</div>
							</div>
							<div className="col-md-6">
							<div className="login_input animation a2">
							<input 
							ref={inputRef2}
							onKeyDown={(e) => handleKeyDown(e, inputRef2)}
							type="text" 
							className="login_input-field" 
							value={givenName}
							onChange={(e) => setGivenName(e.target.value)}
							required/>
							<label className="login_input-label ml-0">{t("reservation.given-name")}</label>
						</div>
							</div>
						</div>
							<div className="animation a3">
							{/* <input type="text" className="login_input-field" required/> */}
							<div style={{fontSize: '1.2rem', color: !gender ? '#8597a3' : '#482979'}}>{t("reservation.gender")}</div>

							<select
							className={errors.gender ? "col-md-12 form__content validate_failed mb-0" : 'col-md-12 form__content mb-0'}
							style={{fontSize: '1.2rem'}}
							value={gender}
							onChange={(e) => {
								setGender(e.target.value)
								errors.gender = ''
							}}
							>
							<option value="" disabled>{t("reservation.gender")}</option>
							{genderValue.map((item, index) => (
								<option value={item.value} key={index}>{item.value}</option>
							))}
						</select>
						</div>
						<div className="row pb-4">
						<div className="login_input animation a4">
						<div style={{fontSize: '1.2rem',color: !day || !month || !year ? '#8597a3' : '#482979'}}>{t("reservation.birth-date")}</div>
						</div>
							<div className="col-md-4">
							<div className="login_input animation a4">
								<DayPicker />
							</div>
							</div>
							<div className="col-md-4">
							<div className="login_input animation a4">
								<MonthPicker />

							</div>
							</div>
							<div className="col-md-4">
							<div className="login_input animation a4">
								<YearPicker />
							</div>
							</div>
						</div>
						<div className="login_input animation a5">
							<input 
							ref={inputRef6}
							onKeyDown={(e) => handleKeyDown(e, inputRef6)}
							type="text" 
							className="login_input-field" 
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							required/>
							<label className="login_input-label ml-0">{t("room_hbt1.emailTitle")}</label>
						</div>
						<div className="login_input animation a6">
							<input 
							ref={inputRef3}
							onKeyDown={(e) => handleKeyDown(e, inputRef3)}
							type="text" 
							maxLength={12}
							className="login_input-field" 
							value={phone}
							onKeyPress={(event) => {
								if (!/[0-9]/.test(event.key)) {
								  event.preventDefault();
								}
							  }}
							onChange={(e) => setPhone(e.target.value)}
							required/>
							<label className="login_input-label ml-0">{t("reservation.phone")}</label>
						</div>
						<div className="login_input animation a7">
							<input 
							ref={inputRef4}
							onKeyDown={(e) => handleKeyDown(e, inputRef4)}
							type="password" 
							className="login_input-field" 
							value={password}
							onChange={(e) => setPassword(e.target.value)}
							required/>
							<label className="login_input-label ml-0">{t("login.password")}</label>
						</div>
						<div className="login_input animation a8">
							<input
							ref={inputRef5}
							onKeyDown={(e) => handleKeyDown(e, inputRef5)} 
							type="password" 
							className="login_input-field" 
							value={confirmPassword}
							onChange={handleConfirmPasswordChange}
							required/>
							<label className="login_input-label ml-0">{t("login.confirm-pass")}</label>
						</div>
						{errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
						<div className="mt-4 animation a9">
							<button className="action-button" onClick={handlePostData}>{t("login.signup")}</button>
						</div>
				<div className="animation a10 mt-3">
						<p  style={{fontSize:"1.2rem"}}>{t("login.acc")}? <Link to ={`/${language}/login`} style={{color:"#482979", fontWeight:600}}>{t("login.login")}</Link></p>
			</div>
					</form>
			</div>
			</div>
			<ToastContainer 
         position="top-right"
         transition={Flip}
         />
		</>
    )
}